import React from "react"
import IntegrationTemplate from "../../templates/integration"
import * as Ui from "components/ui"
import * as Sections from 'components/sections'
import * as Layout from 'components/layout'
import IntegrationStorageContent from 'components/content/integration-storage'
import { meta } from '../../configs/storage-providers';
const ContentPage = () => (
  <IntegrationTemplate
    key="google-drive"
    name="Google Cloud Storage"
    shortDescription="Store files, database, storage and application backups on Google Drive."
    website={"https://cloud.google.com/storage"}
    links={[{text: 'Documentation', url: '/docs'}]}
    category="Storage"
    logo="google-cloud-ico.png"
    metaTitle={meta.title.replace('##provider##', 'Google Cloud Storage')}
    metaDescription={meta.description.replace('##provider##', 'Google Cloud Storage')}
  >
    <IntegrationStorageContent
      keyRef="googlecloud"
      service="Google Cloud Storage"
      provider="Google Cloud"
      learnTags={['google-cloud-storage']}
    />
  </IntegrationTemplate>
)

export default ContentPage
